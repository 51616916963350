* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html {
    font-size: 62.5%;
  }
  body {
    font-size: 1.3rem;
    font-family: 'Montserrat', sans-serif;
    /* font-family: 'Roboto', sans-serif; */
    width: 100vw;
    height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  a {
    text-decoration: none;
    color: inherit;
    cursor:pointer;
  }